import dropdownIcon from 'assets/icons/dropdown.svg';
import Avatar from 'components/common/Avatar';
import { SelectToken } from 'components/common/Form';
import Img from 'components/common/Img';
import TokenImg from 'components/common/TokenImg';
import { getClickable } from 'components/common/Web3Utils';
import { MassPayoutContainer } from 'components/Payments/styles';
import { useActiveWeb3React } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Collapse from 'reactstrap/lib/Collapse';
import { makeSelectPrices, makeSelectTokenList } from 'store/tokens/selectors';
import { makeSelectLoading as makeSelectTeamsLoading } from 'store/view-teams/selectors';
import { formatNumber } from 'utils/number-helpers';
import { constructLabel } from 'utils/tokens';

const getFormattedAmount = (amount = '') => {
  let formattedAmount;
  formattedAmount = `$${formatNumber(amount.split('.')[0], 2)}.${
    amount.split('.')[1]
  }`;

  if (isNaN(amount)) {
    return 'Invalid Amount';
  }

  return formattedAmount || '';
};

const getFormattedToken = (amount = '', token) => {
  let formattedAmount;
  formattedAmount = `${formatNumber(amount.split('.')[0], 2)}.${
    amount.split('.')[1]
  } ${token}`;

  if (isNaN(amount)) {
    return 'Invalid Amount';
  }

  return formattedAmount || '';
};

const renderTeamsRow = ({
  team,
  isAddressInvalid = [],
  salaryToken,
  idx,
  name,
  address,
  salaryAmount,
  prices,
  firstName,
  lastName,
  showNoError,
  chainId,
  editOn,
  peopleId,
  handleAmountEdit,
  token,
}) => {
  const addressError = isAddressInvalid.includes(address);

  const fullName =
    (firstName ? firstName : '') +
    (lastName ? '  ' : '') +
    (lastName ? lastName : '');

  const renderName = !!fullName ? fullName : name;

  return (
    <div
      key={`${peopleId}-${idx}`}
      className={`payee-table-row ${addressError && 'error'} ${
        editOn && 'py-5'
      } d-flex align-items-center`}
    >
      <div className="name d-flex align-items-center">
        <Avatar className="avatar" firstName={renderName} />
        {renderName}
        {'   '}( {getClickable(address, chainId, 'address', true)} )
      </div>

      <div className="token d-flex justify-content-end align-item-center">
        {editOn === true ? (
          <MassPayoutContainer>
            <>
              <TokenImg token={salaryToken} />

              <input
                key={peopleId}
                value={salaryAmount}
                type="number"
                className="editamount"
                style={{ width: '60%' }}
                onChange={e =>
                  handleAmountEdit({ peopleId, team, token }, e.target.value)
                }
              />
            </>
          </MassPayoutContainer>
        ) : (
          <>
            <TokenImg token={salaryToken} />
            {salaryAmount ? formatNumber(salaryAmount, 3) : null}
            {'  '} {salaryToken}
          </>
        )}
      </div>
      <div>
        {prices && prices[salaryToken]
          ? getFormattedAmount((prices[salaryToken] * salaryAmount).toFixed(2))
          : '--'}
      </div>
    </div>
  );
};

const TransactionsTableByTeams = ({
  team,
  token,
  idx,
  isInsufficientBalance = {},
  errorCount = 0,
  payees,
  isAddressInvalid = [],
  showNoError,
  editOn,
  handleAmountEdit,
  teamsToPaid,
  handleSelectToken,
  teamsChangedToken,
}) => {
  const [open, setopen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const prices = useSelector(makeSelectPrices());

  const { chainId } = useActiveWeb3React();
  const tokenList = useSelector(makeSelectTokenList());
  const loadingTeams = useSelector(makeSelectTeamsLoading());
  const [tokensDropdown, setTokensDropdown] = useState([]);
  const showTokenChangeField =
    teamsChangedToken.includes(teamsToPaid?.[team]?.[0]?.departmentId) ||
    isInsufficientBalance.selectToken;

  const { control, watch } = useForm({
    mode: 'onChange',
  });
  const tokenSelected = watch('payout-by-team-token')?.value;

  useEffect(() => {
    if (tokenSelected) {
      handleSelectToken({
        teamDetails: teamsToPaid[team],
        teamName: team,
        tokenSelected,
      });
    }
  }, [tokenSelected]);

  useEffect(() => {
    if (tokenList && tokenList.length > 0 && !tokensDropdown.length) {
      setTokensDropdown(
        tokenList.map(details => ({
          metaValue: details.usd,
          value: details.name,
          label: constructLabel({
            token: details.name,
            component: (
              <div>
                {formatNumber(details.balance)} {details.name}
              </div>
            ),
            imgUrl: details.icon,
          }),
        }))
      );
    }
  }, [tokenList, tokensDropdown]);

  const getTotalAmount = (payees = []) => {
    return payees.reduce((total, { salaryAmount, salaryToken }) => {
      if (salaryToken === 'USD') {
        total += Number(salaryAmount);
      } else {
        total += prices[salaryToken] ? prices[salaryToken] * salaryAmount : 0;
      }
      return total;
    }, 0);
  };

  const getTotalTokens = (payees = []) => {
    return payees.reduce((total, { salaryAmount }) => {
      total += Number(salaryAmount);
      return total;
    }, 0);
  };

  const handleShowPayeeDetails = id => {
    setSelectedToken(id + 1);
    setopen(!open);
  };

  const defaultValue = showTokenChangeField
    ? tokensDropdown.find(
        ele => ele.value === teamsToPaid[team]?.[0]?.salaryToken
      )
    : null;

  return (
    <div
      key={`${token}-${idx}`}
      style={{
        borderBottom: '0.1rem solid rgba(221,220,220,0.8)',
        backgroundColor:
          !showNoError &&
          (isInsufficientBalance.insufficientBalanceFlag || errorCount > 0)
            ? 'rgba(255, 75, 85, 0.04)'
            : '#ffffff',
      }}
      className="table-row-container"
    >
      <div
        onClick={() => handleShowPayeeDetails(idx)}
        className="table-row d-flex align-items-center"
      >
        <div className="token d-flex align-items-left">
          <TokenImg style={{ marginLeft: '0.2rem' }} token={token} />
          {team}

          {Object.keys(isInsufficientBalance).length > 0 ? (
            !isInsufficientBalance.tokenExists && token !== 'ETH' ? (
              <div className="error-text">Token Not Found</div>
            ) : showTokenChangeField ? (
              <div className="d-flex align-items-center">
                <div
                  className="select-wrapper"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <SelectToken
                    name="payout-by-team-token"
                    control={control}
                    required={`Token is required`}
                    isSearchable={false}
                    options={tokensDropdown}
                    isLoading={loadingTeams}
                    placeholder={`Select Token`}
                    defaultValue={defaultValue}
                  />
                </div>
                {isInsufficientBalance.selectToken && (
                  <div className="error-text">Please select your token</div>
                )}
              </div>
            ) : (
              isInsufficientBalance.insufficientBalanceFlag && (
                <div className="error-text">
                  Insufficient balance ( {isInsufficientBalance.balance} {token}{' '}
                  )
                </div>
              )
            )
          ) : null}
        </div>
        <div>{payees && payees.length}</div>
        <div>
          {prices && prices[token]
            ? getFormattedToken(getTotalTokens(payees).toFixed(2), token)
            : '--'}
        </div>
        <div className="amount">
          {prices && prices[token]
            ? getFormattedAmount(getTotalAmount(payees).toFixed(2))
            : '--'}
          <Img src={dropdownIcon} />
        </div>
      </div>
      <Collapse isOpen={open && selectedToken === idx + 1}>
        <div className="payee-table">
          <div className="d-flex align-items-center payee-table-head">
            <div>Payee</div>
            <div>Disbursement</div>
            <div>Amount in usd</div>
          </div>

          <div className="payee-table-body">
            {payees &&
              payees.length > 0 &&
              payees.map(
                (
                  {
                    salaryToken,
                    firstName,
                    lastName,
                    name,
                    address,
                    salaryAmount,
                    peopleId,
                  },
                  vdx
                ) =>
                  renderTeamsRow({
                    team,
                    isAddressInvalid,
                    salaryToken,
                    idx,
                    name,
                    firstName,
                    lastName,
                    address,
                    salaryAmount,
                    prices,
                    showNoError,
                    chainId,
                    editOn,
                    peopleId,
                    handleAmountEdit,
                    token,
                  })
              )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TransactionsTableByTeams;
